import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <div style={{ width: `100%`, backgroundColor: `white`, height: `100vh`, display: `flex`, justifyContent: `space-around`, alignItems: `center` }}>
      <div style={{ width: `90%`, maxWidth: `480px` }}>
        <h1 style={{fontWeight: `700`}}>` Sorry, the page you are looking for could not be found :( `</h1>
        <Link to="/">Home</Link>
      </div>
    </div>
  </Layout>
 
)

export default NotFoundPage
